import "./src/styles/fonts.css";
import "./src/styles/global.css";

export const onInitialClientRender = () => {
  // Get the user's browser language
  const userLanguage =
    window.navigator.language || window.navigator.userLanguage;

  // Use a mapping to match browser language to supported languages

  const currentPath = window.location.pathname;
  // Set the language to the detected language or fallback to the default
  const selectedLanguage = userLanguage?.slice(0, 2) || "en";
  // Redirect to the appropriate language-specific homepage
  if (selectedLanguage === "fr" && !currentPath.startsWith(`/${"fr"}/`)) {
    window.location.href = `/fr${currentPath}`;
  } 
};
onInitialClientRender();
